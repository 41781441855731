<template>
	<div id="app">
		<sa-index></sa-index>
	</div>
</template>

<script>
	import SaIndex from './sa-resources/index/sa-index.vue';
	export default {
		name: 'App',
		components: {
			SaIndex
		}
	}
</script>

<style>
	
</style>
