export default [
{perms: "productDetail-add", view: () => import('@/sa-view/productDetail/add.vue')},
{perms: "config-add", view: () => import('@/sa-view/config/add.vue')},
{perms: "user-add", view: () => import('@/sa-view/user/add.vue')},
{perms: "material-list", view: () => import('@/sa-view/material/list.vue')},
{perms: "implement-list", view: () => import('@/sa-view/implement/list.vue')},
{perms: "permission-list", view: () => import('@/sa-view/permission/list.vue')},
{perms: "material-detail", view: () => import('@/sa-view/material/detail.vue')},
{perms: "materialPartner-add", view: () => import('@/sa-view/materialPartner/add.vue')},
{perms: "taskRisk-list", view: () => import('@/sa-view/taskRisk/list.vue')},
{perms: "taskSchedule-list", view: () => import('@/sa-view/taskSchedule/list.vue')},
{perms: "feedback-add", view: () => import('@/sa-view/feedback/add.vue')},
{perms: "article-list", view: () => import('@/sa-view/article/list.vue')},
{perms: "product-add", view: () => import('@/sa-view/product/add.vue')},
{perms: "indexTask-list", view: () => import('@/sa-view/indexTask/list.vue')},
{perms: "feedback-list", view: () => import('@/sa-view/feedback/list.vue')},
{perms: "project-add", view: () => import('@/sa-view/project/new.vue')},
{perms: "sys-config", view: () => import('@/sa-view/cfg/system-cfg.vue')},
{perms: "operation-detail", view: () => import('@/sa-view/operation/detail.vue')},
{perms: "test-add", view: () => import('@/sa-view/test/add.vue')},
{perms: "project-list", view: () => import('@/sa-view/project/list.vue')},
{perms: "material-add", view: () => import('@/sa-view/material/add.vue')},
{perms: "config-list", view: () => import('@/sa-view/config/list.vue')},
{perms: "finance-list", view: () => import('@/sa-view/finance/list.vue')},
{perms: "file-list", view: () => import('@/sa-view/file/list.vue')},
{perms: "materialPartner-list", view: () => import('@/sa-view/materialPartner/list.vue')},
{perms: "finance-index", view: () => import('@/sa-view/finance/index.vue')},
{perms: "article-add", view: () => import('@/sa-view/article/add.vue')},
{perms: "log-add", view: () => import('@/sa-view/log/add.vue')},
{perms: "operation-list", view: () => import('@/sa-view/operation/list.vue')},
{perms: "check-list", view: () => import('@/sa-view/check/list.vue')},
{perms: "purchaseLine-list", view: () => import('@/sa-view/purchaseLine/list.vue')},
{perms: "purchase-list", view: () => import('@/sa-view/purchase/list.vue')},
{perms: "story-list", view: () => import('@/sa-view/story/list.vue')},
{perms: "purchaseLine-add", view: () => import('@/sa-view/purchaseLine/add.vue')},
{perms: "operation-add", view: () => import('@/sa-view/operation/add.vue')},
{perms: "category-add", view: () => import('@/sa-view/category/add.vue')},
{perms: "role-list", view: () => import('@/sa-view/role/role-list.vue')},
{perms: "taskTest-add", view: () => import('@/sa-view/taskTest/add.vue')},
{perms: "log-list", view: () => import('@/sa-view/log/list.vue')},
{perms: "deliver-add", view: () => import('@/sa-view/deliver/add.vue')},
{perms: "productDetail-list", view: () => import('@/sa-view/productDetail/list.vue')},
{perms: "indexTask-add", view: () => import('@/sa-view/indexTask/add.vue')},
{perms: "user-list", view: () => import('@/sa-view/user/list.vue')},
{perms: "task-add", view: () => import('@/sa-view/task/add.vue')},
{perms: "task-detail", view: () => import('@/sa-view/task/one-detail.vue')},
{perms: "test-list", view: () => import('@/sa-view/test/list.vue')},
{perms: "productDetail-detail", view: () => import('@/sa-view/productDetail/detail.vue')},
{perms: "file-add", view: () => import('@/sa-view/file/add.vue')},
{perms: "deliver-list", view: () => import('@/sa-view/deliver/list.vue')},
{perms: "taskSchedule-add", view: () => import('@/sa-view/taskSchedule/add.vue')},
{perms: "operation-home", view: () => import('@/sa-view/operation/home.vue')},
{perms: "check-add", view: () => import('@/sa-view/check/add.vue')},
{perms: "role-add", view: () => import('@/sa-view/role/add.vue')},
{perms: "task-one", view: () => import('@/sa-view/task/one.vue')},
{perms: "taskRisk-add", view: () => import('@/sa-view/taskRisk/add.vue')},
{perms: "file-list", view: () => import('@/sa-view/file/file-list.vue')},
{perms: "task-index", view: () => import('@/sa-view/task/index.vue')},
{perms: "operation-index", view: () => import('@/sa-view/operation/index.vue')},
{perms: "purchase-add", view: () => import('@/sa-view/purchase/add.vue')},
{perms: "project-detail", view: () => import('@/sa-view/project/detail.vue')},
{perms: "taskTest-list", view: () => import('@/sa-view/taskTest/list.vue')},
{perms: "implement-add", view: () => import('@/sa-view/implement/add.vue')},
{perms: "product-list", view: () => import('@/sa-view/product/list.vue')},
{perms: "category-list", view: () => import('@/sa-view/category/list.vue')},
{perms: "permission-add", view: () => import('@/sa-view/permission/add.vue')},
{perms: "finance-add", view: () => import('@/sa-view/finance/add.vue')},
{perms: "story-add", view: () => import('@/sa-view/story/add.vue')},
{perms: "project-purchase", view: () => import('@/sa-view/project/index.vue')},
{perms: "contract-list", view: () => import('@/sa-view/contract/list.vue')},
{perms: "contract-add", view: () => import('@/sa-view/contract/add.vue')},
{perms: "task-list", view: () => import('@/sa-view/task/list.vue')},
{perms: "check-detail", view: () => import('@/sa-view/check/detail.vue')},
{perms: "operation-one", view: () => import('@/sa-view/operation/one.vue')},
{perms: "feedback-detail", view: () => import('@/sa-view/feedback/detail.vue')},
];
